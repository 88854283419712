import React, { useState } from "react";
import { Button, Select, Spin, notification } from "antd";
import { GraphConfigComponentWrapper } from "./graphConfig.style";
import InterfaceHeader from "../InterfaceHeader";
import axios from "axios";
import config from "../../config/Api";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { setTocData } from "../../reducersAndActions/Actions";
import { withWindowSizeHOC } from "../../Common/withWindowSizeHOC";

const openNotification = (msg, type) => {
  let args;
  if (type === true) {
    args = {
      message: "Success",
      description: msg,
      duration: 1,
      placement: "bottomLeft",
    };
    notification.success(args);
  } else if (type === false) {
    args = {
      message: "Failure",
      description: msg,
      duration: 1,
      placement: "bottomLeft",
    };
    notification.error(args);
  } else {
    args = {
      message: "Warning",
      description: msg,
      duration: 3.5,
      placement: "bottomLeft",
    };
    notification.warning(args);
  }
};

function GraphConfig({
  selectedIndicator,
  TOC_DATA,
  setTocData,
  custom_report_flag,
}) {
  const [loading, setLoading] = useState(false);
  const [infoTabVis, setInfoTabVis] = useState(false);
  const [data, setData] = useState(
    TOC_DATA?.config?.indicator.filter(
      (item) => item.indicator_type == selectedIndicator
    )[0] || {}
  );
  const [nesting] = useState([]);

  const handleInputChange = (nestArr, splitByIndex, graphIndex, key, value) => {
    let tempData = JSON.parse(JSON.stringify(data));

    let currentObj = tempData;
    nestArr.forEach((index) => {
      currentObj = currentObj.splitBy[index];
    });

    if (nestArr.length) {
      if (currentObj.child_slide_config?.graphs?.length) {
        if (splitByIndex !== null) {
          currentObj.child_slide_config.graphs[graphIndex][key] = value.trim();
        } else {
          currentObj.graphs[graphIndex][key] = value.trim();
        }
      }
    } else {
      currentObj.graphs[graphIndex][key] = value.trim();
    }

    setData(tempData);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      // console.log("DATA FOUND", {
      //   data,
      //   report_id: TOC_DATA.report_id,
      //   custom_report: TOC_DATA?.dimensions?.custom_report,
      // });
      const response = await axios.post(
        `${config.api.base_url}/api/editing/config-graph`,
        {
          data,
          report_id: TOC_DATA.report_id,
          custom_report: TOC_DATA?.dimensions?.custom_report,
        }
      );

      if (TOC_DATA?.dimensions?.custom_report && custom_report_flag) {
        localStorage.setItem(
          "custom_report_details",
          JSON.stringify({
            slug: TOC_DATA?.slug,
            custom_report: TOC_DATA?.dimensions?.custom_report,
          })
        );
      }

      if (!response) {
        openNotification("Something went wrong. Please try again.", false);
        setLoading(false);
        throw new Error("Something went wrong");
      }

      openNotification("Data updated successfully", true);
      let temp_toc_data = JSON.parse(JSON.stringify(TOC_DATA || {}));
      // console.log("TOC DATA", temp_toc_data);
      if (
        temp_toc_data.hasOwnProperty("config") &&
        temp_toc_data.config.hasOwnProperty("indicator")
      ) {
        temp_toc_data.config.indicator = temp_toc_data?.config?.indicator?.map(
          (item) =>
            item.indicator_type === data.indicator_type &&
            item.title === data.title
              ? data
              : item
        );
      }
      setTocData(temp_toc_data);
      setLoading(false);
    } catch (error) {
      console.log("ERROR -->", error);
      openNotification(
        error?.response?.message || "Something went wrong. Please try again.",
        false
      );
      // setTocData({});
      setLoading(false);
    }
  };

  return (
    <>
      <InterfaceHeader />
      <GraphConfigComponentWrapper>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}>
          <div className="card">
            <div
              className="leftContainer"
              style={{
                width: infoTabVis ? "70%" : "100%",
              }}>
              <h2
                style={{
                  textAlign: "center",
                }}>
                Configurations
              </h2>
              <div id="form-wrapper">
                {
                  <ConfigForm
                    obj={data}
                    handleInputChange={handleInputChange}
                    nesting={nesting}
                  />
                }
              </div>
              <button
                className="save-btn"
                disabled={loading}
                onClick={handleSubmit}>
                {loading ? "Saving" : "Save"}
              </button>
            </div>

            <div className={infoTabVis ? "infoContainer" : "info"}>
              <Button
                type="default"
                danger={infoTabVis}
                onClick={() => setInfoTabVis(!infoTabVis)}>
                {infoTabVis ? "Close Guide" : "Open Guide"}
              </Button>
              {infoTabVis && (
                <div className="infoContent">
                  <b>
                    Configure Hub's base year, company market year and study
                    period
                  </b>
                  <br />
                </div>
              )}
            </div>
          </div>
        </div>
      </GraphConfigComponentWrapper>
    </>
  );
}

function ConfigForm({
  obj,
  handleInputChange,
  nesting = [],
  splitByIndex = null,
}) {
  if (obj.child_slide_config?.graphs?.length) {
    return obj.child_slide_config.graphs.map((graph, position) => (
      <div id="form-container" key={position}>
        <h3>{`Graph ${position + 1}`}</h3>
        {Object.entries(graph).map(([key, value], index) => (
          <label key={index}>
            {key} <br />
            <input
              type="text"
              defaultValue={value}
              onChange={(e) =>
                handleInputChange(
                  nesting,
                  splitByIndex,
                  position,
                  key,
                  e.target.value
                )
              }
            />
            <br />
          </label>
        ))}
      </div>
    ));
  }

  if (obj.splitBy?.length) {
    return obj.splitBy.map((item, index) => {
      return (
        <ConfigForm
          key={index}
          obj={item}
          handleInputChange={handleInputChange}
          nesting={[...nesting, index]}
          splitByIndex={index}
        />
      );
    });
  }

  if (obj.graphs?.length) {
    return obj.graphs.map((graph, position) => (
      <div id="form-container" key={position}>
        <h3>{`Graph ${position + 1}`}</h3>
        {Object.entries(graph).map(([key, value], index) => (
          <label htmlFor={key} key={index}>
            {key} <br />
            {key === "graphType" ? (
              <Select
                style={{
                  display: "block",
                }}
                id="selectType"
                placeholder="Select Type"
                value={value}
                maxLength=""
                onChange={(val) =>
                  handleInputChange(nesting, null, position, key, val)
                }
                options={[
                  "groupedbar",
                  "stackedColumn",
                  "bar",
                  "pie",
                  "msline",
                ].map((item) => ({
                  label: item,
                  value: item,
                }))}
              />
            ) : (
              <input
                type="text"
                id={key}
                defaultValue={value}
                onChange={(e) =>
                  handleInputChange(
                    nesting,
                    null,
                    position,
                    key,
                    e.target.value
                  )
                }
              />
            )}
          </label>
        ))}
      </div>
    ));
  }

  return null;
}

function mapStateToPros(state) {
  return {
    PLACEMENT: state.PLACEMENT,
    PUBLISHED_DRAWER_VISIBLE: state.PUBLISHED_DRAWER_VISIBLE,
    DRAFT_DRAWER_VISIBLE: state.DRAFT_DRAWER_VISIBLE,
    draft_versions: state.draft_versions,
    published_versions: state.published_versions,
    ACTIVE_TAB: state.ACTIVE_TAB,
    draft_stage: state.draft_stage,
    no_drafts_found: state.no_drafts_found,
    USER_DATA: state.USER_DATA,
    draft_versions_loading: state.draft_versions_loading,
    published_versions_loading: state.published_versions_loading,
    parent_id: state.parent_id,
    template_type: state.template_type,
    selected_indicator_heading: state.selected_indicator_heading,
    cache_id: state.cache_id,
    companyType: state.companyType,
    update_company_profile_data: state.update_company_profile_data,
    fetch_full_data: state.fetch_full_data,
    edit_graph: state.edit_graph,
    edit_graph_column: state.edit_graph_column,
    edit_graph_data: state.edit_graph_data,
    TOC_DATA: state.TOC_DATA,
    editGraphConfig: state.editGraphConfig,
    custom_report_flag: state.custom_report_flag,
  };
}

const mapDispatchToProps = {
  setTocData,
};

export default withWindowSizeHOC(
  withRouter(connect(mapStateToPros, mapDispatchToProps)(GraphConfig))
);
