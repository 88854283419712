import React, { Component } from "react";
import SearchComponent from ".././Search";
import MarketActivities from "../MarketActivities";
import MasterMEsExport from "../MasterMEs";
import DownloadReports from "../DownloadReports";
import Interface from "../Interface";
import InterFaceHeader from "../InterfaceHeader";
import "../../App.css";
import {
  setTocData,
  setSelectedCustomSlideData,
  setTocRawData,
  setSearchParams,
  setActiveTab,
  setSwitchState,
  setPublishedDrawer,
  setDraftDrawer,
  setDraftStage,
  setNoDraftsFound,
  setSelectStageChangeLoader,
  setDraftId,
  setMarketActivityParams,
  setMasterMEParams,
  setDownloadReportsParams,
  setAnalysisHeadingEditorState,
  setHLPParams,
  setEditGraph,
  setEditGraphConfig,
} from "../../reducersAndActions/Actions";
import {
  Tabs,
  Button,
  Switch,
  Menu,
  Select,
  Modal,
  Input,
  notification,
  Table,
} from "antd";
import { message } from "antd";
import { LoadingOutlined, UserOutlined } from "@ant-design/icons";
import { LayoutWrapper } from "../../styles/Layout.style";
import { withRouter } from "react-router";
import { withWindowSizeHOC } from "../../Common/withWindowSizeHOC";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import config from "../../config/Api";
import HubDetails from "../HLPDataUpload";
import ChatButton from "../../Common/ChatButton/index";
import SectionPdf from "./LayoutModals/SectionPdf";
import SelectSampleVariantModal from "./LayoutModals/SelectSampleVarientModal";

const { Option } = Select;

const { TabPane } = Tabs;
const antIcon = <LoadingOutlined style={{ fontSize: 16 }} />;
class Layout extends Component {
  state = {
    blob: [],
    downloadloader: false,
    UpdateRddownloadloader: false,
    showSampleOptions: false,
    pdfurl: "",
    new_slug: "",
    isSlideEditable: false,
    // checked: false,
    publish_modal_warning: false,
    slide_delete_modal_warning: false,
    draft_publish_loader: false,
    duplicate_rd_modal_warning: false,
    duplicate_rd_modal_message: "",
    reset_urls_modal_warning: false,
    enable_delete_leaf_slide_btn: false,
    show_dimensions_modal: false,
    show_custom_slide_edit: false,
    selectedSections: [],
    syncSlideModalVis: false,
    syncingSlide: false,
  };
  hubLeaderMenu = (
    <Menu>
      <Menu.Item key="1" icon={<UserOutlined />}>
        1st menu item
      </Menu.Item>
      <Menu.Item key="2" icon={<UserOutlined />}>
        2nd menu item
      </Menu.Item>
      <Menu.Item key="3" icon={<UserOutlined />}>
        3rd menu item
      </Menu.Item>
    </Menu>
  );

  copyDimensions = () => {
    const copyObj = {
      hub_id: this.props.TOC_DATA.hub_id,
    };

    Object.entries(this.props.dimensions).forEach(
      (item) => (copyObj[`dimensions.${item[0]}`] = item[1])
    );
    window.navigator.clipboard.writeText(JSON.stringify(copyObj));
    notification.success({
      message: "Info",
      description: "Dimensions copied successsfully",
    });
  };

  slideEditFlag = (value = false) => {
    this.setState({ isSlideEditable: value });
  };

  componentDidMount() {
    if (!this.props.TOC_DATA) {
      this.props.setSearchParams(false);
    }

    this.props.setEditGraph(false);
  }

  setGetTocParams = (item) => {
    this.props.history.push(`/editing/report/${item.slug}`);
    this.props.setTocData(item);
    this.props.setTocRawData([]);
    this.props.setSearchParams(true);
    // this.props.setActiveTab("report");
    this.props.setActiveTab("drafts");
  };

  tabChange = (value) => {
    this.props.setActiveTab(value);
    if (value === "report") {
      this.props.history.push(`/editing/report/${this.props.TOC_DATA.slug}`);
    } else {
      this.props.history.push(`/editing/${value}`);
    }
  };
  tabClick = (key, event) => {};

  tabData = () => {
    if (this.props.ACTIVE_TAB === "report") {
      return !this.props.SEARCH_PARAMS ? null : (
        <Interface
          TocApiParams={this.props.TOC_DATA}
          reportName={this.props.TOC_DATA.title}
          setSearchParams={this.props.setSearchParams}
          resetSlug={this.resetSlug}
        />
      );
    } else if (
      this.props.ACTIVE_TAB === "published" ||
      this.props.ACTIVE_TAB === "drafts"
    ) {
      return !this.props.SEARCH_PARAMS ? null : (
        <Interface
          TocApiParams={this.props.TOC_DATA}
          reportName={this.props.TOC_DATA.title}
          edit_mode={this.props.switch_state}
          draft_drawer_visible={this.props.DRAFT_DRAWER_VISIBLE}
          published_drawer_visible={this.props.PUBLISHED_DRAWER_VISIBLE}
          handleSwitchchange={this.handleSwitchchange}
          slideEditFlag={this.slideEditFlag}
          setSearchParams={this.props.setSearchParams}
          resetSlug={this.resetSlug}
          show_custom_slide_edit={this.state.show_custom_slide_edit}
          onCloseCustomSlideEdit={this.handleCloseCustomeSlide}
        />
      );
    } else if (this.props.ACTIVE_TAB === "datacharts") {
      return <div>Data and Charts</div>;
    } else if (this.props.ACTIVE_TAB === "customize") {
      return <div>Customize</div>;
    } else if (this.props.ACTIVE_TAB === "faqs") {
      return <div>FAQs</div>;
    } else {
      return <div>Purchases</div>;
    }
  };

  deleteOldRD = async () => {
    const payload = {
      hub_id: this.props.TOC_DATA.hub_id,
      dimensions: this.props.TOC_DATA.dimensions,
      deleteOlderRd: this.state.duplicate_rd_modal_message ? true : false,
      meta: { sample: false, rd: true },
    };
    this.setState({
      downloadloader: true,
    });
    try {
      let url = config.api.base_url;
      let response = await axios.post(`${url}/api/createRd`, payload);
      if (response.status === 200) {
        message.success(`rd generated successfully ${response.data.slugs}`);
        let a = document.createElement("a");
        a.target = "_blank";
        a.href = `${config.api.website_url}industry-reports/${response.data.slugs}`;
        a.click();
        message.success(`Old RD Deleted successfully ${response.data.slugs}`);
        this.setState({
          downloadloader: false,
          duplicate_rd_modal_warning: false,
        });
      } else if (response.data.message) {
        message.error(response.message);
        this.setState({
          downloadloader: false,
        });
      } else if (!response) {
        message.error("Failed");
        this.setState({
          downloadloader: false,
        });
      }
    } catch (error) {
      message.error("Failed");
      this.setState({
        downloadloader: false,
      });
    }
  };
  updateRDSlug = async () => {
    const payload = {
      hub_id: this.props.TOC_DATA.hub_id,
      dimensions: this.props.TOC_DATA.dimensions,
      updateRdSlug: (this.state.new_slug + "")
        .trim()
        .toLowerCase()
        .replace(/ /g, "-"),
      meta: { sample: false, rd: true },
    };
    this.setState({
      UpdateRddownloadloader: true,
      new_slug: "",
    });
    try {
      let url = config.api.base_url;
      let response = await axios.post(`${url}/api/createRd`, payload);
      if (response.status === 200) {
        message.success(`rd generated successfully ${payload.updateRdSlug}`);
        let a = document.createElement("a");
        a.target = "_blank";
        a.href = `${config.api.website_url}industry-reports/${payload.updateRdSlug}`;
        a.click();
        message.success(
          `Rd generated successfully with new slug ${payload.updateRdSlug}`
        );
        this.setState({
          UpdateRddownloadloader: false,
          duplicate_rd_modal_warning: false,
        });
      } else if (response.data.message) {
        message.error(response.message);
        this.setState({
          UpdateRddownloadloader: false,
        });
      } else if (!response) {
        message.error("Failed");
        this.setState({
          UpdateRddownloadloader: false,
        });
      }
    } catch (error) {
      message.error("Failed");
      this.setState({
        UpdateRddownloadloader: false,
      });
    }
  };
  deleteOldUrls = async () => {
    const payload = {
      hub_id: this.props.TOC_DATA.hub_id,
      dimensions: this.props.TOC_DATA.dimensions,
    };
    this.setState({
      downloadloader: true,
    });
    try {
      let url = config.api.base_url;
      let response = await axios.delete(
        `${url}/api/additional_routes/deleteHubUrls`,
        { data: payload }
      );
      if (response.status === 200) {
        message.success(
          `${this.props.TOC_DATA.title} report and sample url delete successfully..!`
        );

        this.setState({
          downloadloader: false,
          reset_urls_modal_warning: false,
        });
      } else {
        message.error(`Something went wrong, please try again later...!`);
        this.setState({
          downloadloader: false,
        });
      }
    } catch (e) {
      message.error("Failed");
      this.setState({
        downloadloader: false,
        reset_urls_modal_warning: false,
      });
    }
  };

  generatePdf = async (report) => {
    try {
      let payload;
      if (
        report === "Report" ||
        (report + "").toLowerCase() === "pptgen" ||
        (report + "").toLowerCase() === "pptgensample"
      ) {
        const isSample =
          (report + "").toLowerCase() === "pptgensample" ? true : false;
        payload = {
          hub_id: this.props.TOC_DATA.hub_id,
          dimensions: this.props.TOC_DATA.dimensions,
          meta: { sample: isSample },
        };
        this.setState({
          downloadloader: true,
        });
      } else if (report + "" === "showSampleOptions") {
        this.setState({
          downloadloader: false,
          showSampleOptions: true,
        });
      } else if ((report + "").toLowerCase() === "sample") {
        payload = {
          hub_id: this.props.TOC_DATA.hub_id,
          dimensions: this.props.TOC_DATA.dimensions,
          // sample: true,
          meta: { sample: true, reseller: false },
        };
        this.setState({
          downloadloader: true,
        });
      } else if (report + "" === "v1Sample") {
        payload = {
          hub_id: this.props.TOC_DATA.hub_id,
          dimensions: this.props.TOC_DATA.dimensions,
          // sample: true,
          meta: { sample: true, reseller: false, v1Sample: true },
        };
        this.setState({
          downloadloader: true,
        });
      } else if ((report + "").toLowerCase() === "rd") {
        payload = {
          hub_id: this.props.TOC_DATA.hub_id,
          dimensions: this.props.TOC_DATA.dimensions,
          meta: { sample: false, rd: true },
        };
        this.setState({
          downloadloader: true,
        });
      } else if ((report + "").toLowerCase() === "reset_urls") {
        payload = {
          hub_id: this.props.TOC_DATA.hub_id,
          dimensions: this.props.TOC_DATA.dimensions,
          meta: { sample: false, rd: true },
        };
        this.setState({
          downloadloader: true,
        });
      } else if (report + "" === "openSectionPDFModal") {
        this.setState({
          downloadloader: false,
          openSectionPDFModal: true,
        });
      }
      if (
        (report + "").toLowerCase() === "report" ||
        (report + "").toLowerCase() === "sample" ||
        (report + "").toLowerCase() === "v1sample"
      ) {
        try {
          let url = config.api.puppeteer_ms_url;
          let response = await axios.post(`${url}/api/createReport`, {
            ...payload,
            leadAutomation: this.props.leadAutomation,
          });

          message.success("Success");
          this.downloadReport(response.data.pdfUrl);
        } catch (e) {
          let error = { ...e };
          error = error.response.data.message || " some error occred";
          console.log(error);
          notification.error({
            message: error,
          });
          message.error("Failed");
          this.setState({
            downloadloader: false,
          });
        }
      } else if (
        (report + "").toLowerCase() === "pptgen" ||
        (report + "").toLowerCase() === "pptgensample"
      ) {
        let url = config.api.pptgen_url;
        let response = await axios.post(`${url}createPPT`, payload);

        if (response.status === 200) {
          message.success("Success");
          this.downloadReport(response.data.url);
        } else if (response.data.message) {
          message.error(response.message);
          this.setState({
            downloadloader: false,
          });
        } else if (!response) {
          message.error("Failed");
          this.setState({
            downloadloader: false,
          });
        }
      } else if ((report + "").toLowerCase() === "rd") {
        let url = config.api.base_url;
        let response = await axios.post(`${url}/api/createRd`, payload);
        if (response.status === 200) {
          message.success(`rd generated successfully ${response.data.slugs}`);
          // let a = document.createElement("a");
          // a.target = "_blank";
          // a.href = `${config.api.website_url}industry-reports/${response.data.slugs}`;
          // a.click();
          this.setState({
            downloadloader: false,
          });
        } else if (response.data.message) {
          message.error(response.message);
          this.setState({
            downloadloader: false,
          });
        } else if (!response) {
          message.error("Failed");
          this.setState({
            downloadloader: false,
          });
        }
      } else if ((report + "").toLowerCase() === "reset_urls") {
        this.setState({
          downloadloader: false,
          reset_urls_modal_warning: true,
        });
      }
    } catch (error) {
      if (error?.response?.status === 409) {
        this.setState({
          duplicate_rd_modal_warning: true,
          duplicate_rd_modal_message: error?.response?.data?.message,
        });
      }
      message.error(
        error?.response?.data?.message ||
          error.message ||
          "PPT Generation failed. Please try again later"
      );
      this.setState({
        downloadloader: false,
      });
    }
  };

  resetSlug = () => {
    this.setState({
      new_slug: "",
    });
  };

  handleDuplicateModalCancel = () => {
    this.setState({
      duplicate_rd_modal_warning: false,
    });
  };
  handleResetUrlsModalCancel = () => {
    this.setState({
      reset_urls_modal_warning: false,
      openSectionPDFModal: false,
      showSampleOptions: false,
    });
  };

  reset = () => {
    this.setState({
      downloadloader: false,
      selectedSections: [],
    });
  };

  downloadReport = (pdfurl) => {
    var link = document.createElement("a");
    link.download = "sample.pdf";
    link.href = `${pdfurl}`;
    link.target = "_blank";
    link.dispatchEvent(new MouseEvent("click"));
    this.setState({
      downloadloader: false,
    });
  };

  handleSwitchchange = (checked) => {
    this.props.setNoDraftsFound(false);
    // this.setState({
    //   checked: checked,
    // });
    this.props.setSwitchState(checked);
    this.props.setDraftId(null);
    this.props.setAnalysisHeadingEditorState("");
  };
  handleCloseCustomeSlide = () => {
    this.setState({
      show_custom_slide_edit: false,
    });
  };

  handleDraftTabStageChange = (stage) => {
    this.continueStagechange(stage);
  };

  showPublishedDrawer = () => {
    this.props.setPublishedDrawer(true);
  };

  showDraftDrawer = () => {
    this.props.setDraftDrawer(true);
  };

  handlePublishModalWarningCancel = () => {
    this.setState({
      publish_modal_warning: false,
    });
  };

  continueDraftPublish = () => {
    this.setState({
      draft_publish_loader: true,
    });
    //User agreed, proceed to publish
    this.props.setDraftStage("PUBLISHED");
    const data = {
      type: this.props.template_type,
      stage: "PUBLISHED",
      parent_id: this.props.parent_id,
      cacheId: this.props.cache_id,
      draftId: this.props.draft_id,
      hub_id: this.props.TOC_DATA.hub_id,
      dimensions: this.props.TOC_DATA.dimensions,
    };

    /* CHECK IF USER HAS ACCESS */
    if (
      this.props.USER_DATA.obj.access.move.includes(
        this.props.draft_versions.stage || "INITIAL"
      )
    ) {
      let deepCloneData = JSON.parse(JSON.stringify(data));
      if (deepCloneData.type === "regulatoryFramework2") {
        deepCloneData.type = "regulatoryFramework";
      }
      deepCloneData["custom_report"] =
        this.props?.TOC_DATA?.dimensions?.custom_report;
      axios({
        method: "PATCH",
        url: `${config.api.base_url}/api/editing/drafts`,
        data: deepCloneData,
      })
        .then((response) => {
          this.setState({
            // checked: false,
            draft_publish_loader: false,
            publish_modal_warning: false,
          });
          this.props.setSwitchState(false);

          if (response.status === 200 || response.status === 201) {
            this.props.setActiveTab("published");
            notification.success({
              message: "Info",
              description: response.data.message,
            });
          }
        })
        .catch((error) => {
          this.props.setDraftStage(
            this.props.draft_stage_from_get_specific_data
          ); //revert to previous stage if api call fails
          this.setState({
            draft_publish_loader: false,
            publish_modal_warning: false,
          });
          if (error.response) {
            notification.error({
              message: "Error",
              description: error.response.data.message,
            });
          }
        });
    } else {
      this.props.setDraftStage(this.props.draft_stage_from_get_specific_data); //revert to previous stage
      this.setState({
        publish_modal_warning: false,
        draft_publish_loader: false,
      });
      notification.warning({
        message: "Info",
        description: "You dont have access to move stage",
      });
    }
  };

  continueStagechange = (stage) => {
    if (stage === "PUBLISHED") {
      // If the stage about to move is PUBLISHED, show warning modal
      this.setState({
        publish_modal_warning: true,
      });
    } else {
      //Let the stage change happen. Don't show any modal.
      this.props.setDraftStage(stage);
      this.props.setSelectStageChangeLoader(true);
      const data = {
        type: this.props.template_type,
        stage: stage,
        parent_id: this.props.parent_id,
        draftId: this.props.draft_id,
        cacheId: this.props.cache_id,
      };

      /* CHECK IF USER HAS ACCESS */
      if (
        this.props.USER_DATA.obj.access.move.includes(
          this.props.draft_versions.stage || "INITIAL"
        )
      ) {
        let deepCloneData = JSON.parse(JSON.stringify(data));
        if (deepCloneData.type === "regulatoryFramework2") {
          deepCloneData.type = "regulatoryFramework";
        }
        axios({
          method: "PATCH",
          url: `${config.api.base_url}/api/editing/drafts`,
          data: deepCloneData,
        })
          .then((response) => {
            this.props.setSelectStageChangeLoader(false);
            if (response.status === 200 || response.status === 201) {
              // throw new Error('something went wrong');
              notification.success({
                message: "Info",
                description: response.data.message,
              });
            }
          })
          .catch((error) => {
            this.props.setSelectStageChangeLoader(false);
            this.props.setDraftStage(
              this.props.draft_stage_from_get_specific_data
            ); //revert to previous stage if api call fails
            if (error.response) {
              notification.error({
                message: "Error",
                description: error.response.data.message,
              });
            }
          });
      } else {
        this.props.setDraftStage(this.props.draft_stage_from_get_specific_data); //revert to previous stage
        notification.warning({
          message: "Info",
          description: "You dont have access to move stage",
        });
      }
    }
  };

  onclickOfSearch = (event) => {
    this.setState(
      {
        new_slug: event.target.value,
      },
      () => {}
    );
  };

  deleteThisSlide = async (parent_id) => {
    const data = {
      parent_ids: [parent_id],
      type: "typedetail",
    };
    axios({
      method: "DELETE",
      url: `${config.api.base_url}/api/editing/delete_doc`,
      data: data,
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          notification.success({
            message: "Info",
            description: response.data.message,
          });
          this.setState({
            slide_delete_modal_warning: false,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error.response.data.message,
        });
      });
  };

  handleSyncSlideBtnClick = () => {
    this.setState({
      syncSlideModalVis: true,
    });
  };

  syncSlide = () => {
    this.setState({
      syncingSlide: true,
    });

    let body = {
      ...this.props.selectedSlideData,
    };

    body.obj.dimensions = {
      ...this.props.dimensions,
      custom_report: this.props.dimensions.custom_report + "_sync_slide",
    };
    let URL = config.api.base_url;

    axios
      .post(`${URL}/api/getSpecificData`, body)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            syncingSlide: false,
            syncSlideModalVis: false,
          });
          notification.success({
            message: "Info",
            description: "Slide Synced Successfully.",
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err, "ERR");
        notification.success({
          message: "error",
          description: "Something went wrong! Please try again.",
        });
        this.setState({
          syncingSlide: false,
          syncSlideModalVis: false,
        });
      });
  };

  render() {
    const { width, parent_id, template_type } = this.props;
    // const hub_id = this.props.TOC_DATA.hub_id;
    // const hub_title = this.props.TOC_DATA.title;
    // const report_id = this.props.TOC_DATA.report_id;
    const { hub_id, title, report_id } = this.props.TOC_DATA;

    const user = this.props?.USER_DATA;
    let newTemplateType =
      template_type === "regulatoryFramework2"
        ? "regulatoryFramework"
        : template_type;

    return (
      <LayoutWrapper
        mobile={width > 960 ? false : true}
        // checked={this.state.checked}
        checked={this.props.switch_state}
        template_type={this.props.template_type}>
        <div className="layoutCOntainer">
          <InterFaceHeader
            title={this.props.TOC_DATA.title}
            generatePdf={this.generatePdf}
            indicator={antIcon}
            spinning={this.state.downloadloader}
          />

          {!this.props.SEARCH_PARAMS && (
            <>
              <div
                className="searchContainer"
                style={{
                  marginTop: "4rem",
                }}></div>
              <SearchComponent setGetTocParams={this.setGetTocParams} />
            </>
          )}
          {this.props.MARKET_ACTIVITY_PARAMS && <MarketActivities />}
          {this.props.MASTER_ME_PARAMS && <MasterMEsExport />}
          {this.props.DOWNLOAD_REPORTS_PARAMS && <DownloadReports />}
          {this.props.HLP_PARAMS && <HubDetails />}
          {this.props.SEARCH_PARAMS &&
            !this.props.MARKET_ACTIVITY_PARAMS &&
            !this.props.MASTER_ME_PARAMS &&
            !this.props.DOWNLOAD_REPORTS_PARAMS &&
            !this.props.HLP_PARAMS && (
              <>
                <div className="antd-tabContainer">
                  <Tabs
                    activeKey={this.props.ACTIVE_TAB}
                    centered
                    animated={width > 960 ? true : false}
                    tabPosition={width > 960 ? "top" : "bottom"}
                    onChange={(value) => this.tabChange(value)}
                    className="simulateClick">
                    <TabPane tab="Drafts" key="drafts">
                      <Link to="/editing/report" />
                    </TabPane>
                    <TabPane tab="Published" key="published">
                      <Link to="/editing/report" />
                    </TabPane>
                  </Tabs>

                  <div className="editing-controls-container">
                    {this.state.isSlideEditable && (
                      <Switch
                        // defaultChecked
                        checked={this.props.switch_state}
                        onChange={this.handleSwitchchange}
                        checkedChildren="EDIT"
                        unCheckedChildren="VIEW"
                        disabled={this.props.switch_disabled}
                        className="edit-mode-switch"
                      />
                    )}
                    {[
                      "typedetail",
                      "typeoverview",
                      "indicator",
                      "keyStrategicMoves",
                      "marketShares",
                      "executive_summary",
                      "regulatoryFramework",
                    ].includes(newTemplateType) &&
                      this.props.switch_state && (
                        <ChatButton
                          hub_id={hub_id}
                          hub_title={title}
                          parent_id={parent_id}
                          template_type={template_type}
                          user={user.obj}
                          baseUrl={config.api.base_url}
                          report_id={report_id}
                        />
                      )}

                    {this.props.ACTIVE_TAB === "published" ? (
                      <div className="tab-buttons-container">
                        {/* <Button type="primary" disabled={!this.state.checked}>PUBLISHED</Button> */}
                        <Button
                          type="link"
                          onClick={this.showPublishedDrawer}
                          disabled={!this.props.switch_state}>
                          Published Versions
                        </Button>
                      </div>
                    ) : (
                      <div className="tab-buttons-container">
                        <Select
                          value={this.props.draft_stage}
                          style={{ width: 120 }}
                          onChange={this.handleDraftTabStageChange}
                          disabled={
                            !this.props.switch_state ||
                            this.props.draft_stage === "NONE"
                          }
                          className="draft-tab-dropdown"
                          loading={this.props.select_stage_change_loader}>
                          {this.props.USER_DATA &&
                            this.props.USER_DATA.obj &&
                            this.props.USER_DATA.obj.access &&
                            this.props.USER_DATA.obj.access.move &&
                            this.props.USER_DATA.obj.access.move.length &&
                            this.props.USER_DATA.obj.access.move.map((item) => {
                              return <Option value={item}>{item}</Option>;
                            })}
                        </Select>

                        <Button
                          type="link"
                          onClick={this.showDraftDrawer}
                          disabled={!this.props.switch_state}>
                          Draft Versions
                        </Button>
                      </div>
                    )}
                  </div>
                  <div>
                    {["typeoverview", "typedetail", "indicator"].includes(
                      this.props.template_type
                    ) &&
                      ["TECH", "RESEARCH"].includes(
                        this.props.USER_DATA.obj.department
                      ) && (
                        <Button
                          type="primary"
                          id="layout-buttons"
                          style={{
                            borderRadius: "5px",
                          }}
                          danger={this.props.edit_graph}
                          onClick={(e) => {
                            if (this.props.edit_graph) {
                              setTimeout(() => {
                                window.location.reload();
                              }, 1000);
                            }
                            this.props.setEditGraph(!this.props.edit_graph);
                          }}>
                          {this.props.edit_graph ? "Close Excel" : "Edit Graph"}
                        </Button>
                      )}
                  </div>
                  <div>
                    {["indicator"].includes(this.props.template_type) &&
                      ["TECH", "RESEARCH"].includes(
                        this.props.USER_DATA.obj.department
                      ) && (
                        <Button
                          type="primary"
                          id="layout-buttons"
                          style={{
                            borderRadius: "5px",
                          }}
                          danger={this.props.editGraphConfig}
                          onClick={() => {
                            this.props.setEditGraphConfig(
                              !this.props.editGraphConfig
                            );

                            if (this.props.editGraphConfig) {
                              setTimeout(() => {
                                window.location.reload();
                              }, 1000);
                            }
                          }}>
                          {this.props.editGraphConfig
                            ? "Close Config"
                            : "Edit Config"}
                        </Button>
                      )}
                  </div>
                  {this.props.template_type && (
                    <Button
                      type="primary"
                      id="layout-buttons"
                      onClick={() =>
                        this.setState({
                          show_dimensions_modal: true,
                        })
                      }>
                      View Dimensions
                    </Button>
                  )}
                  {this.props?.dimensions?.custom_report ? (
                    <Button
                      type="primary"
                      id="layout-buttons"
                      onClick={this.handleSyncSlideBtnClick}>
                      Sync Slide
                    </Button>
                  ) : null}
                </div>

                <div className="body-section">{this.tabData()}</div>
              </>
            )}
        </div>
        <Modal
          title="Dimensions"
          visible={this.state.show_dimensions_modal}
          onCancel={() =>
            this.setState({
              show_dimensions_modal: false,
            })
          }
          footer={[
            <Button type="primary" onClick={this.copyDimensions}>
              Copy Dimensions
            </Button>,
          ]}>
          <Table
            columns={[
              {
                title: "Name",
                dataIndex: "name",
                key: "name",
              },
              {
                title: "Value",
                dataIndex: "value",
                key: "value",
              },
            ]}
            dataSource={
              Object.entries(this.props?.dimensions || {})?.map(
                (item, index) => ({
                  key: index + 1,
                  name: item[0].replaceAll("_", " ").toUpperCase(),
                  value: item[1],
                })
              ) || []
            }
            pagination={false}
          />
        </Modal>

        <Modal
          title="Notification"
          visible={this.state.slide_delete_modal_warning}
          onCancel={() =>
            this.setState({
              slide_delete_modal_warning: false,
            })
          }
          onOk={() => this.deleteThisSlide(this.props.parent_id)}
          okText="Continue"
          cancelText="Cancel"
          footer={[
            <p
              style={{
                textAlign: "left",
              }}>
              Type DELETE to continue.
            </p>,
            <Input
              style={{
                marginBottom: "10px",
              }}
              type="text"
              onInput={(e) => {
                if (e.target.value.trim() === "DELETE") {
                  this.setState({
                    enable_delete_leaf_slide_btn: true,
                  });
                } else {
                  this.setState({
                    enable_delete_leaf_slide_btn: false,
                  });
                }
              }}
            />,
            <Button
              key="back"
              onClick={() =>
                this.setState({
                  slide_delete_modal_warning: false,
                })
              }>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              danger
              // loading={this.state.draft_publish_loader}
              onClick={() => this.deleteThisSlide(this.props.parent_id)}
              disabled={!this.state.enable_delete_leaf_slide_btn}>
              Delete
            </Button>,
          ]}>
          Once you delete this slide, the analysis and Drafts will be lost. Are
          you sure you want to delete this slide?
        </Modal>

        <Modal
          title="Notification"
          visible={this.state.publish_modal_warning}
          onCancel={this.handlePublishModalWarningCancel}
          onOk={this.continueDraftPublish}
          okText="Continue"
          cancelText="Cancel"
          footer={[
            <Button key="back" onClick={this.handlePublishModalWarningCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={this.state.draft_publish_loader}
              onClick={this.continueDraftPublish}>
              Continue
            </Button>,
          ]}>
          Once you publish this draft, it replaces the current version with this
          version. Are you sure?
        </Modal>

        <Modal
          title="Sorry! RD already exist"
          visible={this.state.duplicate_rd_modal_warning}
          okText="Continue"
          cancelText="Cancel"
          onCancel={this.handleDuplicateModalCancel}
          centered
          footer={[
            <Button key="back" onClick={this.handleDuplicateModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={this.deleteOldRD}
              loading={this.state.downloadloader}>
              Proceed
            </Button>,
          ]}>
          Do u want to modify slug to resolve duplicate slug issue?
          <Input onChange={this.onclickOfSearch} />
          <br />
          <br />
          <Button
            key="submit"
            type="primary"
            onClick={this.updateRDSlug}
            loading={this.state.UpdateRddownloadloader}>
            Update Slug
          </Button>
          <hr />
          {this.state.duplicate_rd_modal_message}
        </Modal>

        <Modal
          title="Are you sure removing existing Sample, Report?"
          visible={this.state.reset_urls_modal_warning}
          okText="Continue"
          cancelText="Cancel"
          onCancel={this.handleResetUrlsModalCancel}
          centered
          footer={[
            <Button key="back" onClick={this.handleResetUrlsModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={this.deleteOldUrls}
              loading={this.state.downloadloader}>
              Proceed
            </Button>,
          ]}>
          {this.state.duplicate_rd_modal_message}
        </Modal>
        {this.state.openSectionPDFModal && (
          <SectionPdf
            openSectionPDFModal={this.state.openSectionPDFModal}
            handleResetUrlsModalCancel={this.handleResetUrlsModalCancel}
            downloadloader={this.state.downloadloader}
            states={this.props}
            reset={this.reset}
            downloadReport={this.downloadReport}
          />
        )}
        {this.state.showSampleOptions && (
          <SelectSampleVariantModal
            showSampleOptions={this.state.showSampleOptions}
            handleResetUrlsModalCancel={this.handleResetUrlsModalCancel}
            downloadloader={this.state.downloadloader}
            states={this.props}
            reset={this.reset}
            generatePdf={this.generatePdf}
          />
        )}

        <Modal
          title="Are you sure you want to Sync this Slide?"
          visible={this.state.syncSlideModalVis}
          okText="Continue"
          cancelText="Cancel"
          onCancel={() => this.setState({ syncSlideModalVis: false })}
          centered
          footer={[
            <Button
              key="back"
              onClick={() => this.setState({ syncSlideModalVis: false })}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={this.syncSlide}
              loading={this.state.syncingSlide}>
              Sync Now
            </Button>,
          ]}></Modal>
      </LayoutWrapper>
    );
  }
}

function mapStateToPros(state) {
  return {
    IS_AUTH: state.IS_AUTH,
    SEARCH_PARAMS: state.SEARCH_PARAMS,
    MARKET_ACTIVITY_PARAMS: state.MARKET_ACTIVITY_PARAMS,
    HLP_PARAMS: state.HLP_PARAMS,
    MASTER_ME_PARAMS: state.MASTER_ME_PARAMS,
    DOWNLOAD_REPORTS_PARAMS: state.DOWNLOAD_REPORTS_PARAMS,
    TOC_DATA: state.TOC_DATA,
    TOC_RAW_DATA: state.TOC_RAW_DATA,
    ACTIVE_TAB: state.ACTIVE_TAB,
    switch_state: state.switch_state,
    PLACEMENT: state.PLACEMENT,
    PUBLISHED_DRAWER_VISIBLE: state.PUBLISHED_DRAWER_VISIBLE,
    DRAFT_DRAWER_VISIBLE: state.DRAFT_DRAWER_VISIBLE,
    draft_versions: state.draft_versions,
    published_versions: state.published_versions,
    USER_DATA: state.USER_DATA,
    draft_stage: state.draft_stage,
    draft_stage_from_get_specific_data:
      state.draft_stage_from_get_specific_data,
    select_stage_change_loader: state.select_stage_change_loader,
    published_access_warning: state.published_access_warning,
    switch_disabled: state.switch_disabled,
    parent_id: state.parent_id,
    cache_id: state.cache_id,
    template_type: state.template_type,
    draft_id: state.draft_id,
    dimensions: state.dimensions,
    edit_graph: state.edit_graph,
    editGraphConfig: state.editGraphConfig,
    custom_report_flag: state.custom_report_flag,
    leadAutomation: state.leadAutomation,
    selectedSlideData: state.selectedSlideData,
  };
}

const mapDispatchToProps = {
  setTocData,
  setSelectedCustomSlideData,
  setTocRawData,
  setSwitchState,
  setSearchParams,
  setMarketActivityParams,
  setMasterMEParams,
  setDownloadReportsParams,
  setHLPParams,
  setActiveTab,
  setPublishedDrawer,
  setDraftDrawer,
  setDraftStage,
  setNoDraftsFound,
  setSelectStageChangeLoader,
  setDraftId,
  setAnalysisHeadingEditorState,
  setEditGraph,
  setEditGraphConfig,
};

export default withWindowSizeHOC(
  withRouter(connect(mapStateToPros, mapDispatchToProps)(Layout))
);
