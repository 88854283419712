import React, { useState, useEffect, useCallback } from "react";
import { Input, notification, Spin } from "antd";
import axios from "axios";
import config from "../config/Api";
import { debounce } from "lodash";
import { withWindowSizeHOC } from "../Common/withWindowSizeHOC";
import { withRouter } from "react-router";
import { connect } from "react-redux";

const { Search } = Input;

const SearchComponent = ({ setGetTocParams, custom_report_flag }) => {
  const [searchValue, setSearchValue] = useState("");
  const [openSearchResult, setOpenSearchResult] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingTitle, setLoadingTitle] = useState("");
  const [searchData, setSearchData] = useState([]);
  const url = config.api.base_url;

  const fetchSearchResults = (value) => {
    setLoading(true);
    setSearchData([]);
    axios
      .get(`${url}/api/searchReport`, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        params: {
          title: value,
          pageNo: "1",
          custom_report: custom_report_flag,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setSearchData(response.data);
        } else {
          setSearchData([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        setSearchValue("");
        setLoading(false);
      });
  };

  const debouncedSearch = useCallback(debounce(fetchSearchResults, 600), []);

  const onInputSearch = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    if (value) {
      debouncedSearch(value);
    } else {
      setLoading(false);
      setSearchData([]);
    }
  };

  const getSelectedReportInfo = async (item) => {
    try {
      setLoadingTitle(item.title);
      let payload = {
        report_id: item.report_id,
        custom_report: custom_report_flag,
      };

      if (custom_report_flag) {
        payload["title"] = item.title;
      }
      const data = await axios.post(`${url}/api/getReportConfig`, payload);
      setLoadingTitle("");
      setGetTocParams(data.data);
    } catch (e) {
      setLoadingTitle("");
      console.log(e);
      notification.error({
        message: "Error opening the report, please contact tech",
      });
    }
  };
  const onclickOfSearch = () => {};

  return (
    <div style={{ width: "60%", margin: "200px auto" }}>
      <div className="searchContainer">
        <Search
          placeholder="Type to Search..."
          allowClear
          enterButton="Search"
          size="large"
          onSearch={onclickOfSearch}
          onChange={onInputSearch}
        />
        {searchValue ? (
          <div
            className="searchResult"
            style={{
              ...(loading && {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }),
            }}>
            <Spin spinning={loading}>
              {typeof searchData !== "string" &&
              searchData &&
              searchData.length ? (
                searchData.map((item, i) => (
                  <div
                    className="hoverEffect"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      backgroundColor:
                        loadingTitle === item.title ? "#bebebe2b" : "unset",
                      padding: "4px 10px",
                      borderRadius: "6px",
                      ...(loadingTitle === item.title && {
                        transform: "scale3d(1.02, 1.02, 1.02)",
                      }),
                    }}
                    key={i}>
                    <p
                      onClick={() => getSelectedReportInfo(item)}
                      className="search-list-element">
                      {item.title}
                    </p>
                    {loadingTitle === item.title && (
                      <Spin size="small" spinning={true} />
                    )}
                  </div>
                ))
              ) : (
                <p>{loading ? "" : "No data"}</p>
              )}
            </Spin>
          </div>
        ) : null}
      </div>
    </div>
  );
};

function mapStateToPros(state) {
  return {
    custom_report_flag: state.custom_report_flag,
  };
}

const mapDispatchToProps = {};

export default withWindowSizeHOC(
  withRouter(connect(mapStateToPros, mapDispatchToProps)(SearchComponent))
);
