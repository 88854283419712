import React, { useState } from "react";
import InterfaceHeader from "../InterfaceHeader";
import { DeleteDataStyleWrapper } from "./CustomReport.style";
import { Button, notification, Select, Table, Tag } from "antd";
import axios from "axios";
import config from "../../config/Api";
import loader from "../../images/spinner.gif";
import CreateCustomReportModal from "./CreateCustomReportModal";
import ConfirmationModal from "./ConfirmationModal";

const openNotification = (msg, type) => {
  let args,
    key = msg;
  if (key === true) {
    args = {
      message: "Success",
      description: "Data Deleted successful.",
      duration: 3.5,
      placement: "topRight",
    };
    notification.success(args);
  } else if (key === false) {
    args = {
      message: "Failure",
      description: "Failed to delete data.",
      duration: 3.5,
      placement: "topRight",
    };
    notification.error(args);
  } else if (type) {
    args = {
      message: type,
      description: key,
      duration: 3.5,
      placement: "topRight",
    };
    notification[type](args);
  } else {
    args = {
      message: "Warning",
      description: key,
      duration: 3.5,
      placement: "topRight",
    };
    notification.warning(args);
  }
};

function CustomReportComponent() {
  const [infoTabVis, setInfoTabVis] = React.useState(false);
  const [hubData, setHubData] = React.useState([]);
  const [searchQuery, setSearchQuery] = useState({
    search: "",
    hub_id: "",
  });

  const [onGoingApiCall, setOnGoingApiCall] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const [selectedReport, setSelectedReport] = useState(null);

  const [openCreateModal, setOpenCreateModal] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [customReportData, setCustomReportData] = React.useState([]);

  const [confirmationText, setConfirmationText] = useState("");
  const [confirmationModalVis, setConfirmationModalVis] = useState(false);
  const [seletedConfirmationModal, setSeletedConfirmationModal] =
    useState(null);

  const confirmationHandlerMapping = {
    delete: deleteCustomReport,
    report: handleGenerateCustomReport,
    sync: handleSyncReport,
  };

  const handleCancelConfirmationModal = () => {
    setConfirmationText("");
    setConfirmationModalVis(false);
    setSeletedConfirmationModal(null);
  };

  const getHubList = async () => {
    setLoading(true);
    setOnGoingApiCall(true);
    if (hubData.length == 0) {
      let full_access = false;
      await axios
        .get(`${config.api.base_url}/api/hubs/list?full_access=${full_access}`)
        .then((response) => {
          if (response.status === 200) {
            setHubData(response.data.obj);
          }
          setLoading(false);
          setOnGoingApiCall(false);
        })
        .catch((error) => {
          openNotification("Unable to load hub list");
          setLoading(false);
          setOnGoingApiCall(false);
        });
    }
  };

  React.useEffect(() => {
    getHubList();
  }, []);

  const handleHubChange = async (value) => {
    setSearchQuery((p) => {
      return { ...p, hub_id: value };
    });

    await fetchCustomReports(value);
  };

  const fetchCustomReports = async (hub_id) => {
    if (hub_id) {
      setOnGoingApiCall(true);
      axios
        .get(
          `${config.api.base_url}/api/custom/report?hub_id=${hub_id}&search=${searchQuery.search}`
        )
        .then((resp) => {
          setOnGoingApiCall(false);
          setCustomReportData(resp.data);
          openNotification("Custom Report fetched successfully.", "success");
        })
        .catch((error) => {
          setOnGoingApiCall(false);
          console.log("ERROR OCCURED WHILE FETCHING REPORTS", error);
          openNotification(
            "Unable to fetch report. Please try again.",
            "error"
          );
        });
    }
  };

  const handlePreview = (obj) => {
    window.open(`/custom-report-preview/${obj._id}`, "_blank");
  };

  const handleConfirmationModal = (text) => {
    setConfirmationModalVis(true);
    setConfirmationText(text);
  };

  async function deleteCustomReport() {
    setConfirmationModalVis(false);
    setConfirmationText("");
    setSeletedConfirmationModal(null);

    setLoading(true);
    try {
      let deleteReport = await axios.delete(
        `${config.api.base_url}/api/custom/report/${selectedReport._id}`
      );
      setCustomReportData((prev) =>
        prev.filter((item) => item._id != selectedReport._id)
      );
      notification.success(
        deleteReport?.data?.message || "Report Deleted successfully"
      );
    } catch (error) {
      console.log("ERROR OCCURED IN deleteCustomReport", error);
      notification.error(
        error?.response?.data?.message ||
          "Something went wrong while deleting report"
      );
    }
    setLoading(false);
  }

  async function handleGenerateCustomReport() {
    setConfirmationModalVis(false);
    setConfirmationText("");
    setSeletedConfirmationModal(null);

    try {
      setLoading(true);
      let generateReport = await axios.post(
        `${config.api.puppeteer_ms_url}/api/generate-custom-report/${selectedReport._id}`
      );

      if (generateReport.data.url) {
        window.open(generateReport.data.url, "_blank");
      }

      notification.success(
        generateReport?.data?.message || "Custom Report generated successfully"
      );
    } catch (error) {
      console.log("ERROR OCCURED IN handleGenerateCustomReport", error);
      notification.error(
        error?.response?.data?.message ||
          "Something went wrong while generating custom report"
      );
    }
    setLoading(false);
  }

  async function handleSyncReport() {
    setConfirmationModalVis(false);
    setConfirmationText("");
    setSeletedConfirmationModal(null);

    setLoading(true);
    try {
      let syncReport = await axios.post(
        `${config.api.base_url}/api/custom/sync-custom-report/${selectedReport._id}`,
        {
          report_id: selectedReport.report_id,
        }
      );

      notification.success(
        syncReport?.data?.message || "Report Synced successfully"
      );
    } catch (error) {
      console.log("ERROR OCCURED IN handleSyncReport", error);
      openNotification(
        error?.response?.data?.message ||
          "Something went wrong. Please try again.",
        "error"
      );
    }
    setLoading(false);
  }

  const customReportColumn = [
    {
      title: "Serial No.",
      dataIndex: "serial",
      key: "serial",
    },
    {
      title: "Variant Name",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Variant ID",
      dataIndex: "custom_report",
      key: "custom_report",
    },
    {
      title: "Client Email",
      dataIndex: "client_email",
      key: "client_email",
      render: (_, { client_email }) =>
        client_email?.map((email) => (
          <Tag
            style={{
              margin: "2px",
            }}
            key={email}>
            {email}
          </Tag>
        )),
    },
    {
      title: "Report URL",
      dataIndex: "custom_reports_url",
      key: "custom_reports_url",
      render: (url) => (
        <Button
          type={url ? "link" : "ghost"}
          onClick={() => url && window.open(url, "_blank")}>
          {url ? "Report URL" : "NA"}
        </Button>
      ),
    },

    {
      title: "Delete",
      dataIndex: "",
      key: "delete",
      render: (row) => (
        <Button
          loading={loading}
          disabled={loading}
          onClick={() => {
            setSelectedReport(row);
            setSeletedConfirmationModal("delete");
            handleConfirmationModal("Are you sure you want to delete this?");
          }}
          danger>
          Delete Report
        </Button>
      ),
    },

    // {
    //   title: "Sync",
    //   dataIndex: "",
    //   key: "sync",
    //   render: (row) => (
    //     <Button
    //       loading={loading}
    //       disabled={loading}
    //       onClick={() => {
    //         setSelectedReport(row);
    //         setSeletedConfirmationModal("sync");
    //         handleConfirmationModal(
    //           "Are you sure you want to sync this report data?"
    //         );
    //       }}>
    //       Sync Report
    //     </Button>
    //   ),
    // },
    // {
    //   title: "Variant",
    //   dataIndex: "",
    //   key: "variant",
    //   render: (row) => (
    //     <Button
    //       loading={loading}
    //       disabled={loading}
    //       onClick={() => {
    //         console.log("CREATE VARIANT REPORT");
    //       }}>
    //       Create Variant
    //     </Button>
    //   ),
    // },
  ];

  return (
    <>
      {" "}
      <InterfaceHeader />
      <DeleteDataStyleWrapper>
        <div className="wrapper">
          <div className="card">
            <div
              className="leftContainer"
              style={{
                width: infoTabVis ? "70%" : "100%",
              }}>
              <h2
                style={{
                  textAlign: "center",
                  flexGrow: 1,
                  margin: "20px 0 30px",
                }}>
                Custom Reports
              </h2>{" "}
              <div className="contentWrapper">
                <div className="options-container">
                  <div className="dropdownContainer">
                    <label htmlFor="selectHub">Select Hub :</label>
                    <Select
                      disabled={onGoingApiCall}
                      style={{
                        display: "block",
                        width: "200px",
                      }}
                      id="selectHub"
                      placeholder="select"
                      value={searchQuery.hub_id}
                      loading={loading}
                      maxLength=""
                      onChange={handleHubChange}
                      options={hubData.map((item, index) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                      autoFocus={true}
                    />
                  </div>
                  <Button
                    type="primary"
                    onClick={() => setOpenCreateModal(true)}>
                    Create New Custom Report
                  </Button>
                </div>

                {searchQuery?.hub_id &&
                  (customReportData && !onGoingApiCall ? (
                    <div className="custom-report-container">
                      <Table
                        style={{
                          width: "95%",
                          overflow: "auto",
                          margin: "20px auto",
                          textTransform: "capitalize",
                        }}
                        columns={customReportColumn}
                        dataSource={customReportData?.map((item, index) => ({
                          ...item,
                          serial: index + 1,
                        }))}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "50px 0",
                      }}>
                      <img src={loader} alt="loading" />
                    </div>
                  ))}
              </div>
            </div>

            <div className={infoTabVis ? "infoContainer" : "info"}>
              <Button
                type="default"
                danger={infoTabVis}
                onClick={() => setInfoTabVis(!infoTabVis)}>
                {infoTabVis ? "Close Guide" : "Open Guide"}
              </Button>
              {infoTabVis && (
                <div className="infoContent">
                  <b>
                    <h4>Delete Segmentation Data in Bulk.</h4>
                    <br />
                    <div>
                      <p>⏺ Select your hub.</p>
                      <p>
                        ⏺ Enter the dimensions of the report which you would
                        like to delete.
                      </p>
                      <p>
                        ⏺ Click on Count Results and verify the count with the
                        number of reports data you want to delete.
                      </p>
                      <p>
                        ⏺ Click Delete and then confirm to delete the data.
                      </p>
                      <p>
                        ⏺ A copy of deleted data will be downloaded on your
                        device.
                      </p>
                      <p>
                        ⏺ Deleted data can be uploaded back using Upload Data
                        button.
                      </p>
                      <br />
                      <h4>Steps to Reupload Deleted Segmentation Data.</h4>
                      <br />
                      <div>
                        <p>
                          ⏺ To upload the deleted data you can either upload
                          the ME data sheet from the Upload Sheet section or you
                          can Select Hub for which you want to upload the data.
                        </p>
                        <p>
                          ⏺ Select the JSON file and then click on Upload
                          button.
                        </p>
                      </div>
                      <hr></hr>
                      <h4>
                        <center>
                          Please contact Tech in case of any query.
                        </center>
                      </h4>
                    </div>
                  </b>
                </div>
              )}
            </div>
          </div>
        </div>
        {openCreateModal && (
          <CreateCustomReportModal
            open={openCreateModal}
            setOpenCreateModal={setOpenCreateModal}
            hubData={hubData}
            setCustomReportData={setCustomReportData}
            selected_hub_id={searchQuery.hub_id}
          />
        )}
      </DeleteDataStyleWrapper>
      <ConfirmationModal
        title={confirmationText}
        open={confirmationModalVis}
        onCancel={handleCancelConfirmationModal}
        onConfirmation={() =>
          confirmationHandlerMapping[seletedConfirmationModal]()
        }
      />
    </>
  );
}

export default CustomReportComponent;
