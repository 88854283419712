import React from "react";
import { Typography, Select, Tabs, Button, Spin } from "antd";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Analysis from "../components/Analysis";
import { RegulatoryFrameworkV2Wrapper } from "../styles/RegulatoryFramework.style";
import {
  setSelectedIndicatorHeading,
  setIndicatorObjectToShow,
  setDraftDataAnalysis,
  setDraftDataSources,
  setDraftVersions,
  getDrafts,
  getPublishedVersions,
  setNoDraftsFound,
  setDraftStage,
  setDraftId,
  setParentId,
  saveDraftApi,
  directPublishApi,
} from "../reducersAndActions/Actions";
import SourcesEditor from "../components/Editor";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withWindowSizeHOC } from "../Common/withWindowSizeHOC";

const { Title } = Typography;
var Base64 = require("js-base64").Base64;

const { Option } = Select;
const { TabPane } = Tabs;

function ReturnTitleAndBulletPoints(arrayOfContent) {
  arrayOfContent.map((item1) => {
    return item1.analysis.forEach(function (BulletPoint, j) {
      if (!j)
        return (
          <div>
            {item1.type}
            {BulletPoint.text}
          </div>
        );
      else return <div>{BulletPoint.text}</div>;
    });
  });
}

class RegulatoryFramework extends React.Component {
  state = {
    data: this.props.data ? this.props.data : {},
    reseller: false,
    save_draft_loader: false,
    direct_publish_loader: false,
    tabPosition: "top",
    editor_active_tab: "analysis",
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevState && prevState.data.data !== this.props.data.data) {
      this.setState({
        data: this.props.data,
      });
    }
    if (this.props.draft_drawer_visible !== prevProps.draft_drawer_visible) {
      if (this.props.draft_drawer_visible) {
        this.props.getDrafts(
          this.props.parent_id,
          this.props.template_type,
          this.props?.TOC_DATA?.dimensions?.custom_report
        );
      }
    }

    if (
      this.props.published_drawer_visible !== prevProps.published_drawer_visible
    ) {
      if (this.props.published_drawer_visible) {
        this.props.getPublishedVersions(this.props.parent_id);
      }
    }

    if (this.props.edit_mode !== prevProps.edit_mode) {
      this.setState({
        editor_active_tab: "analysis",
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.data && props.data.meta) {
      if (props.data.meta.sample && props.data.meta.reseller) {
        return {
          reseller: true,
        };
      }
    }
    return null;
  }

  onSelectChange = (type) => {
    let object_to_show = this.props.indicator_dropdown_options.filter(
      (item) => item.type === type
    )[0];
    this.props.setSelectedIndicatorHeading(type);
    this.props.setIndicatorObjectToShow(object_to_show);
    this.props.setDraftDataAnalysis(
      object_to_show.analysis && object_to_show.analysis.length
        ? object_to_show.analysis.map((item) => item.text)
        : []
    );
    this.props.setDraftDataSources(
      object_to_show.source && object_to_show.source.length
        ? object_to_show.source.map((item) => item.text)
        : []
    );
    this.props.setParentId(object_to_show.parent_id);
    this.props.setDraftStage(
      object_to_show.current_stage ? object_to_show.current_stage : "INITIAL"
    );
  };

  handleSaveDraft = () => {
    const data = {
      parent_id: this.props.parent_id,
      type: this.props.template_type,
      obj: {
        // analysis: this.props.analysis_editor_state,
        analysis: this.props.analysis_editor_state,
        source: this.props.sources_editor_state,
        stage: this.props.draft_versions.stage
          ? this.props.draft_versions.stage
          : "INITIAL",
        type: this.props.indicator_object_to_show.type,
      },
      custom_report: this.props?.TOC_DATA?.dimensions?.custom_report,
    };
    this.props.saveDraftApi(data);
  };

  handleDirectPublish = () => {
    const data = {
      parent_id: this.props.parent_id,
      type: this.props.template_type,
      obj: {
        analysis:
          this.props.analysis_editor_state.length === 0
            ? this.props.draft_data_analysis
            : this.props.analysis_editor_state,
        source: this.props.sources_editor_state,
        stage: this.props.draft_versions.stage
          ? this.props.draft_versions.stage
          : "INITIAL",
        type: this.props.indicator_object_to_show.type,
      },
      directPublished: true,
      stage: "PUBLISHED",
      cacheId: this.props.cache_id,
      hub_id: this.props.TOC_DATA.hub_id,
      dimensions: this.props.TOC_DATA.dimensions,
      custom_report: this.props?.TOC_DATA?.dimensions?.custom_report,
    };
    this.props.directPublishApi(data);
  };

  handleEditoverlayClick = () => {
    this.props.setNoDraftsFound(false);
    this.props.setDraftStage("INITIAL");
  };

  editorTabChange = (tab) => {
    this.setState({
      editor_active_tab: tab,
    });
  };
  render() {
    const { section_id, slug } = this.props.data;
    const { slideNo } = this.props;
    const pageId = section_id ? Base64.encode(section_id) : undefined;
    let slideNoElementId = undefined;
    if (pageId) {
      slideNoElementId = "slideNO" + pageId;
    }
    if (this.props.edit_mode) {
      return (
        <div
          className="container"
          style={{ height: this.props.fromInterface ? "auto" : "626px" }}>
          <Select
            style={{ width: "100%", marginBottom: "20px" }}
            onChange={this.onSelectChange}
            value={this.props.indicator_object_to_show.type}>
            {this.props.indicator_dropdown_options.map((item) => (
              <Option key={item.type}>{item.type}</Option>
            ))}
          </Select>
          {this.props.initial_draft_versions_loading ||
          this.props.specific_draft_loading ? (
            <Spin style={{ display: "flex", justifyContent: "center" }} />
          ) : this.props.ACTIVE_TAB === "drafts" &&
            this.props.no_drafts_found ? (
            <div
              style={{ border: "1px solid grey", padding: "20px" }}
              className="no-drafts-overlay">
              <p>There is nothing in draft right now</p>
              <Button onClick={this.handleEditoverlayClick}>Edit</Button>
            </div>
          ) : (
            <div className="indicator-editing-container">
              <Tabs
                activeKey={this.state.editor_active_tab}
                tabPosition={this.state.tabPosition}
                onChange={this.editorTabChange}
                tabBarExtraContent={
                  <div style={{ display: "flex", gap: "20px" }}>
                    {this.props.ACTIVE_TAB === "drafts" ? (
                      <Button
                        type="primary"
                        onClick={this.handleSaveDraft}
                        loading={this.props.save_draft_loader}
                        disabled={this.props.character_count_error}>
                        Save
                      </Button>
                    ) : (
                      ""
                    )}
                    {this.props.ACTIVE_TAB === "published" ? (
                      <Button
                        type="primary"
                        onClick={this.handleDirectPublish}
                        loading={this.props.direct_publish_loader}
                        disabled={this.props.character_count_error}>
                        DIRECT PUBLISH
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                }
                type="card"
                style={{ height: "100%" }}>
                <TabPane tab="Analysis" key="analysis" forceRender={true}>
                  <Analysis
                    trends={this.props.draft_data_analysis}
                    edit_mode={this.props.edit_mode}
                    character_count="900"
                  />
                </TabPane>
                <TabPane tab="Sources" key="sources" forceRender={true}>
                  {/* Please enter list of Sources */}
                  <SourcesEditor sources={this.props.draft_data_sources} />
                </TabPane>
              </Tabs>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <RegulatoryFrameworkV2Wrapper id={pageId} className="page">
          {!this.props.fromInterface ||
          this.props.fromInterface === undefined ? (
            <Header slideTitle={this.state.data.type} />
          ) : null}
          <div className="container regulatoryFramework">
            <h2 className="heading regulatoryFramework">
              {this.state.data.title}
            </h2>
            {this.state.data.data.map((CountryData, k) => {
              return (
                <>
                  <div className="regulatoryFrameworkCountry">
                    {CountryData.icon ? (
                      <img
                        className="RegulatoryFrameworkCountryFlag"
                        src={CountryData.icon}
                        style={{ width: "50px" }}
                      />
                    ) : null}
                    <span>{CountryData.country.toUpperCase()}</span>
                  </div>
                  <div className="RegulatoryFrameworkBoxSlide">
                    {CountryData.data.map(function (Box, i) {
                      let Heading = Box.type.toUpperCase();
                      // let BulletPoints = {};
                      // Box.analysis.forEach(function (BulletPoint, j) {
                      //   if (BulletPoint.text !== "")
                      //     !j
                      //       ? (BulletPoints =
                      //           "• " + BulletPoint.text.trim() + "\n" + "\n")
                      //       : (BulletPoints =
                      //           BulletPoints +
                      //           "• " +
                      //           BulletPoint.text.trim() +
                      //           "\n" +
                      //           "\n");
                      // });
                      return (
                        <div
                          className="RegulatoryFrameworkBox"
                          id={"RegulatoryFrameworkBox" + i}>
                          <span
                            className="RegulatoryFrameworkBoxHeading"
                            id={"RegulatoryFrameworkBoxHeading" + i}>
                            {Heading}
                          </span>
                          <p className="RegulatoryFramework_BulletPoints">
                            {/* {" "}
                          {BulletPoints}{" "} */}
                            <Analysis
                              trends={{ analysis: Box.analysis }}
                              fromRegulatoryFrameWork
                              index={i}
                            />
                          </p>
                        </div>
                      );
                    })}
                  </div>

                  {ReturnTitleAndBulletPoints(CountryData.data)}
                </>
              );
            })}
          </div>
          {!this.props.fromInterface ||
          this.props.fromInterface === undefined ? (
            <Footer
              slug={slug}
              slideNo={slideNo}
              slideNoElementId={"27"}
              reseller={this.state.reseller}
            />
          ) : null}
        </RegulatoryFrameworkV2Wrapper>
      );
    }
  }
}

function mapStateToPros(state) {
  return {
    ACTIVE_TAB: state.ACTIVE_TAB,
    indicator_dropdown_options: state.indicator_dropdown_options,
    indicator_object_to_show: state.indicator_object_to_show,
    draft_data_analysis: state.draft_data_analysis,
    draft_data_sources: state.draft_data_sources,
    draft_versions: state.draft_versions,
    no_drafts_found: state.no_drafts_found,
    user_edit_access: state.user_edit_access,
    specific_draft_loading: state.specific_draft_loading,
    initial_draft_versions_loading: state.initial_draft_versions_loading,
    TOC_DATA: state.TOC_DATA,
    cache_id: state.cache_id,
    parent_id: state.parent_id,
    character_count_error: state.character_count_error,
    template_type: state.template_type,
    save_draft_loader: state.save_draft_loader,
    direct_publish_loader: state.direct_publish_loader,
    analysis_editor_state: state.analysis_editor_state,
    sources_editor_state: state.sources_editor_state,
    custom_report_flag: state.custom_report_flag,
  };
}
const mapDispatchToProps = {
  setSelectedIndicatorHeading,
  setIndicatorObjectToShow,
  setDraftDataAnalysis,
  setDraftDataSources,
  setDraftVersions,
  getDrafts,
  getPublishedVersions,
  setNoDraftsFound,
  setDraftStage,
  setDraftId,
  setParentId,
  saveDraftApi,
  directPublishApi,
};

export default withWindowSizeHOC(
  withRouter(connect(mapStateToPros, mapDispatchToProps)(RegulatoryFramework))
);
